import React from 'react'
import { css } from 'styled-components'
import { renderToStaticMarkup } from 'react-dom/server'
import { SvgPortoPattern } from 'components/svgs/svg-porto-pattern'
import { hexToRgb } from 'utils/colors'
import { mediaBreakpointUp, sizes } from './breakpoints'

export const paddingBottomByAspectRatio = (ratio: string) => {
	const [width, height] = ratio.split('/')
	const widthNumber = parseInt(width)
	const heightNumber = parseInt(height)
	return css`
		padding-bottom: ${(heightNumber / widthNumber) * 100 + '%'};
	`
}

export const positionAbsoluteAndStretch = css`
	position: absolute;
	inset: 0;
`

export const ellipses = css`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

export const transition = (property: string, delay = 0) => `
	${property} 200ms linear${delay ? ` ${delay}ms` : ''}
`

export const resetButtonStyles = css`
	background: transparent;
	border: none;
	outline: none;
`

export const resetButtonIconStyles = css`
	${resetButtonStyles}
	padding: 0;
	font-size: 0;
	line-height: 0;
	color: transparent;
`

export const rgbaWithHex = (hex: string, opacity = 1, cssProperty = 'color') => {
	const cssCode = `${cssProperty}: rgba(${hexToRgb(hex) || '0, 0, 0'}, ${opacity});`
	return css`
		${cssCode}
	`
}

export const boxShadowRgbaWithHex = (shadow: string, hex: string, opacity = 1) => {
	const boxShadow = `${shadow} rgba(${hexToRgb(hex) || '0, 0, 0'}, ${opacity})`
	return css`
		box-shadow: ${boxShadow};
	`
}

export const borderRgbaWithHex = (border: string, hex: string, opacity = 1, cssProperty = 'border') => {
	const borderCss = `${cssProperty}: ${border} rgba(${hexToRgb(hex) || '0, 0, 0'}, ${opacity});`
	return css`
		${borderCss}
	`
}

const svgString = encodeURIComponent(renderToStaticMarkup(<SvgPortoPattern />))
const bgDataUri = `url("data:image/svg+xml,${svgString}")`

export const bgPortoPattern = css`
	background-image: ${bgDataUri};
	background-repeat: no-repeat;
	background-position: left center;
	background-size: cover;
	opacity: 0.02;
`

/* TODO: fix text truncation on IE */
// https://css-tricks.com/almanac/properties/l/line-clamp/
export const truncateText = (lines: number) => css`
	@supports (-webkit-line-clamp: ${lines}) {
		/* stylelint-disable-next-line */
		display: -webkit-box;
		-webkit-line-clamp: ${lines};
		/* stylelint-disable-next-line */
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
`

// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
export const wordWrap = () => css`
	hyphens: auto;
	word-break: break-word;
	word-wrap: break-word;
	overflow-wrap: break-word;
`

type Breakpoint = keyof typeof sizes

export const breakpointsMinHeight = (minHeights: Partial<{ [key in Breakpoint]: number }> = {}) => {
	const styles: ReturnType<typeof css>[] = []
	// @ts-ignore
	Object.keys(minHeights).forEach((breakpoint: Breakpoint) => {
		styles.push(css`
			${mediaBreakpointUp(sizes[breakpoint])} {
				min-height: ${minHeights[breakpoint] + 'px'};
			}
		`)
	})
	return css`
		${styles}
	`
}

export const mainGradient = (isLarge = false, color: string) => {
	const start = isLarge ? '36%' : '9%'
	const end = isLarge ? '82%' : '85%'
	const endColor = hexToRgb(color) || '0 0 0'

	return css`
		background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0%) ${start},
			${endColor ? `rgba(${endColor}, 0.85)` : 'rgba(0, 0, 0, 0.85)'} ${end}
		);
	`
}
